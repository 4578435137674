// autosave data states - the values correspond to css classes so don't change them unless you change the class names
// export const AS_STATE_INITIAL = 'initial'
// export const AS_STATE_CHANGED = 'changed';
// export const AS_STATE_NEW = 'new';
// export const AS_STATE_DELETE = 'delete';
// export const AS_STATE_SAVED = 'saved';
// export const AS_STATE_ERROR = 'error';

// autosave data actions
export const AS_ACTION_IDLE = "AS_ACTION_IDLE"; // Nothing happening, all caches clear
export const AS_ACTION_UPDATE = "AS_ACTION_UPDATE"; // Update data in the existing cache and set the save timer
export const AS_ACTION_DELETE = "AS_ACTION_DELETE"; // Delete record immediately
export const AS_ACTION_CREATE = "AS_ACTION_CREATE"; // Create a new record immediately
// export const AS_ACTION_LOAD = 'AS_ACTION_LOAD';         // New data was loaded and guaranteed to not contain any changes
export const AS_ACTION_PURGE = "AS_ACTION_PURGE"; // save immediately and purge the cache

export const AS_RESPONSE_IDLE = "AS_RESPONSE_IDLE";
export const AS_RESPONSE_UPDATED = "AS_RESPONSE_UPDATED";
export const AS_RESPONSE_CREATED = "AS_RESPONSE_CREATED";
export const AS_RESPONSE_DELETED = "AS_RESPONSE_DELETED";


// export const AS_ACTION_RESET = 'AS_ACTION_RESET';               // reset the existing record to the initial data
// export const AS_ACTION_NEW = 'AS_ACTION_NEW';                   // new data - ie: a different data set
// export const AS_ACTION_SAVED = 'AS_ACTION_SAVED';               // data has been saved
// export const AS_ACTION_DELETED = 'AS_ACTION_DELETED';
// export const AS_ACTION_STATE_CHANGE = 'AS_ACTION_STATE_CHANGE'; // the autosave data state has changed to one of AS_STATE_??
