import "./style/scss/main.scss";
import React, {Suspense, useState} from "react";
import Authenticate from "./Security/Authenticate";
import Loading from "./Common/Components/Loading";
import {Provider} from "react-redux";
import store from "./store/store";

const AppLoader = React.lazy(() => import("./AppLoader"));

/**
 *
 * The App.
 * Checks whether a user is authenticated and, if not, directs them to the Authenticate component.
 *
 */
const App = () => {
    const [authenticated, setAuthenticated] = useState(false);

    const userLoggedIn = () => {
        setAuthenticated(true);
    };

    return (
        <div className="main-container">
            { authenticated ?
                <Suspense fallback={ <Loading showWidget="spinner" message="Loading App" useBackground /> }>
                    <Provider store={store}>
                        <AppLoader />
                    </Provider>
                </Suspense>
                :
                <Authenticate onLoggedIn={ userLoggedIn } />
            }
        </div>
    );
};

export default App;
