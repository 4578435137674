import * as storage from "../Utils/Storage";
import {Alert, Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import React, {useState} from "react";
import axios from "axios";
import {getLoginAPI, getFullAPI} from "../APIResolver";

/**
 *
 * Displays the login form and carries out the login
 *
 * @param {Object[]} props
 *
 */
const LoginForm = (props) => {
    const [loginData, setLoginData] = useState({user_name: "",
        password: ""});
    const [error, setError] = useState(null);
    const [buttonState, setButtonState] = useState("button");
    const [formState, setFormState] = useState("login");
    const {onLogin} = props;

    /**
     *
     * User name or password has changed
     *
     * @param {object} e
     *
     */
    const changeHandler = (e) => {
        let data = {...loginData};

        data[e.target.name] = e.target.value;
        setLoginData(data);
        setError(null);
    };

    const storeUserHandler = (userData) => {
        storage.storeUserData(
            userData.access_token,
            userData.uid
        );
        onLogin();
    };

    const login = () => {
        setButtonState("spinner");

        axios.post(
            `${getLoginAPI()}login`,
            {"username": loginData.user_name,
                "password": loginData.password,
                "client": "protouree"}
        )
            .then((response) => {
                storeUserHandler(response.data);
            })
            .catch((error) => {
                if (typeof error.response === "undefined") {
                    setError("Login failed. Protouree cannot communicate with the server. Please check your internet connection.");
                } else {
                    if (error.response.status === 401) {
                        setError("Incorrect user name or password");
                    } else if (error.response.status === 429) {
                        setError(error.response.statusText);
                    } else {
                        setError(error.message);
                    }
                }
                setButtonState("button");
            });
    };

    const wasEnterKeyPressedHandler = (e) => {
        if( e.key === "Enter" ) {
            if (formState === "login") {
                login();
            } else {
                passwordReset();
            }
        }
    };

    /**
     *
     * Forgotten pw link was clicked
     *
     */
    const switchFormState = () => {
        setError(null);
        formState === "login" ? setFormState("fPw") : setFormState("login");
    };

    /**
     *
     * Login button was clicked
     *
     */
    const submitHandler = () => {
        const data = {...loginData};
        let errs = [];

        if (data.user_name.trim() === "") {
            errs.push("user name");
        }
        if (data.password.trim() === "") {
            errs.push("password");
        }
        if (errs.length) {
            setError(`Missing ${errs.join(" and ")}`);
        } else {
            login();
        }
    };


    const passwordReset = () => {
        if( loginData.user_name.trim() === '' ) {
            setError('Please enter your user name');
            return;
        }
        axios.post(`${getFullAPI()}password-reset/password-reset-send-email`, {"user_name": loginData.user_name})
              .then( response => {
                console.log(response.data);
              })
              .catch( error => {
                setError( 'There was an error resetting your password. Please contact your system administrator.' );
              });
    }


    return (
        <div className="pt-login-page">
            <Form>
                { error !== null ?
                    <Alert color="danger">{ error }</Alert>
                    :
                    null
                }
                <FormGroup className="pt-login-dark">
                    <Label for="user_name">User Name</Label>
                    <Input
                        type="text"
                        name="user_name"
                        id="user_name"
                        value={ loginData.user_name }
                        onChange={ changeHandler } onKeyDown={ wasEnterKeyPressedHandler }
                    />
                </FormGroup>
                { formState === "login" ?
                    <>

                        <FormGroup className="pt-login-dark">
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={ loginData.password }
                                onChange={ changeHandler } onKeyDown={ wasEnterKeyPressedHandler }
                            />
                        </FormGroup>
                        <FormGroup className="mt-4 mb-0 d-flex align-items-center justify-content-between">
                            {/* <Button color="link" size="lg" onClick={ switchFormState } className="d-inline-flex align-items-center pl-0">
                                <span>Forgotten password</span>
                            </Button> */}
                            { buttonState === "button" ?
                                <Button color="primary" size="lg" onClick={ submitHandler } className="d-inline-flex align-items-center">
                                    <span className="icon-enter3 mr-2"></span>
                                    <span className="pb-1">Login</span>
                                </Button>
                                :
                                <Spinner key="authspinner" size="sm" color="primary" />
                            }
                        </FormGroup>
                    </>
                    :
                    <FormGroup className="mt-4 mb-0 d-flex align-items-center justify-content-between">
                        <Button color="link" size="lg" onClick={ switchFormState } className="d-inline-flex align-items-center pl-0">
                            <span>Back to Login</span>
                        </Button>
                        <Button className="btn btn-primary btn-lg d-inline-flex align-items-center" onClick={ passwordReset }>
                            <span>Reset password</span>
                        </Button>
                    </FormGroup>
                }
            </Form>
        </div>
    );
};

export default LoginForm;
