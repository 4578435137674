export const getAPI = () => {
    if (window.APIURL === "" || window.APIURL === undefined) {
        // return  { 'url': 'https://protoureeapi.vamostheatre.co.uk/', 'client': 'vamos', 'login': 'vamos'}

        // Local
        // return {"url": "http://dev.protoureeapi/",
        //     "client": "edsci",
        //     "login": "edsci"};
        // return {"url": "http://dev.protoureeapi/",
        //     "client": "demo",
        //     "login": "demo"};

        // Development database
        // return {"url": "https://api.protouree-app.com/",
        //     "client": "dev",
        //     "login": "dev"};

        // Demo Database - don't use unless you're doing a demo
        // return {
        //     "url": "https://api.protouree-app.com/",
        //     "client": "demo",
        //     "login": "demo"
        // };
        return {
            "url": "https://api.protouree-app.com/",
            "client": "edsci",
            "login": "edsci"
        };
    } else {
        return {
            "url": window.APIURL,
            "client": window.APIClient,
            "login": window.APILogin
        };
    }
};

export const getLoginAPI = () => {
    let APIEndpoint = getAPI();
    let loginURL = APIEndpoint.url;

    if (APIEndpoint.login !== "") {
        loginURL = `${loginURL}${APIEndpoint.login}/`;
    }

    return loginURL;
};

export const getFullAPI = () => {
    let APILocation = getAPI();
    let APIURL = APILocation.url;

    if (APILocation.client !== "") {
        APIURL = `${APIURL}${APILocation.client}/`;
    }

    return APIURL;
};

export const getClient = () => {
    let APIEndpoint = getAPI();

    return APIEndpoint.client;
};
