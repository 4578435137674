import * as AutoSaveActionTypes from "./AutoSaveActionTypes";

const initialState = {
    type: "",
    action: AutoSaveActionTypes.AS_RESPONSE_IDLE,
    key: "", // the autosave key which identifies this data
    id: 0, // the id of the saved data
    data: {}, // the data that was saved
    extData: {} // any additional data
};

const reducer = (state = initialState, action) => {
    // console.log(action);
    if (!action) {
        return state;
    }
    switch (action.type) {
    case AutoSaveActionTypes.AS_RESPONSE_UPDATED:
        return {
            action: action.type,
            key: action.key,
            id: action.id,
            data: action.data,
            extData: action.extData
        };
    case AutoSaveActionTypes.AS_RESPONSE_CREATED:
        return {
            action: action.type,
            key: action.key,
            id: action.id,
            data: action.data,
            extData: action.extData
        };
    case AutoSaveActionTypes.AS_RESPONSE_DELETED:
        return {
            action: action.type,
            key: action.key,
            id: action.id,
            data: null,
            extData: action.extData
        };
    default:
        return null;
    }
};

export default reducer;
