import React, {useState} from "react";
import LoginForm from "./LoginForm";
import logo from "../assets/protouree_logo.svg";
import {Spinner} from "reactstrap";
import ValidateToken from "./ValidateToken";

const AUTHSTATE_TOKEN_REQUEST = "AUTHSTATE_TOKEN_REQUEST";
const AUTHSTATE_SHOW_LOGIN_FORM = "AUTHSTATE_SHOW_LOGIN_FORM";

/**
 *
 * Authenticates the user by checking for an existing token.
 * If token not found, displays the login form and
 * logs the user in
 *
 * @param {Object[]} props
 * @param {function} props[].onLoggedIn
 *
 */
const Authenticate = (props) => {
    const [authState, setAuthState] = useState(AUTHSTATE_TOKEN_REQUEST);

    const authenticated = props.onLoggedIn;

    /**
     *
     * Callback from the ValidateToken component - the token was found and NOT validated
     * Display the login form next.
     *
     */
    const tokenNotAuthorisedHandler = () => {
        setAuthState(AUTHSTATE_SHOW_LOGIN_FORM);
    };

    /**
     *
     * Callback from the ValidateToken component - the token was found and validated
     *
     */
    const tokenAuthorisedHandler = () => {
        authenticated();
    };

    /**
     *
     * Callback from the login form after the user has succesfully logged in
     *
     */
    const userLoggedIn = () => {
        authenticated();
    };

    /**
     *
     * Displays the status depending on where we are in the authenication process
     *
     */
    const showStatus = () => {
        switch (authState) {
        case AUTHSTATE_TOKEN_REQUEST:
            return <ValidateToken onTokenNotAuthorised={ tokenNotAuthorisedHandler } onTokenAuthorised={ tokenAuthorisedHandler } />;
        case AUTHSTATE_SHOW_LOGIN_FORM:
            return <LoginForm onLogin={ userLoggedIn } />;
        default:
            return <Spinner key="authspinner" size="sm" color="primary" />;
        }
    };

    return (
        <div className="pt-app-select-page h-100 d-flex align-items-center justify-content-center flex-column mx-4">
            <div className="w-100 text-center">
                <img src={ logo } height="60" width="293" className="my-sm-5" alt="protouree logo" />
                <div className="ticket-panel mx-auto text-left">
                    <span className="white-circle left"></span>
                    <span className="white-circle right"></span>
                    { showStatus() }
                </div>
            </div>
        </div>
    );
};

export default Authenticate;
