import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";
import ReactDOM from "react-dom";

const ErrorFallback = ({error}) => {
    return (
        <div role="alert">
            <p>Sorry, something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
};

ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { }}>
        <App />
    </ErrorBoundary>
    , document.getElementById("root")
);

serviceWorker.unregister();
