import {applyMiddleware, combineReducers, createStore} from "redux";
import AutoSaveReducer from "./AutoSave/AutoSaveReducer";
import AutoSaveResponseReducer from "./AutoSave/AutoSaveResponseReducer";
import globalReducer from "./reducers/Global";
import projectSelectReducer from "./reducers/ProjectSelector";
import thunk from "redux-thunk";
import UserReducer from "./User/UserReducer";

const rootReducer = combineReducers({
    projectSelector: projectSelectReducer,
    global: globalReducer,
    autoSave: AutoSaveReducer,
    autosaveResponse: AutoSaveResponseReducer,
    user: UserReducer
});

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;
