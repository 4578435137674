import * as AutoSaveActionTypes from "./AutoSaveActionTypes";
import _ from "lodash";

const initialState = {
    action: AutoSaveActionTypes.AS_ACTION_IDLE,
    key: null,
    id: 0,
    data: {},
    additionalData: {},
    immediate: false,
    callback: null,
    queue: []
};

/**
 *
 * Create the update state. Push any unsaved data on to the queue -
 * the AutoSaveServer will remove it and save it
 *
 * @param {Object[]} action
 * @param {Object[]} state
 *
 */
const update = (action, state) => {
    let response = {
        action: action.type,
        key: action.key,
        id: action.id,
        data: _.cloneDeep(action.data),
        additionalData: action.additionalData,
        immediate: action.immediate,
        callback: action.callback,
        queue: []
    };

    if (state.data !== null && state.key !== action.key) {
        response.queue.push(state);
    }
    return response;
};

/**
 *
 * The autosave server sets the autosave to idle after it has
 * performed it's action(s) but another request may come in
 * between it finishing and sending the IDLE state.
 *
 * @param {Object[]} action
 * @param {Object[]} state
 *
 */
const idle = (action, state) => {
    if (state.data !== null) {
        if (state.id !== action.id && state.key !== action.key) {
            return state;
        }
    }

    return {...state,
        action: action.type,
        key: action.key,
        id: 0,
        data: null};
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case AutoSaveActionTypes.AS_ACTION_PURGE:
        return {
            ...state,
            action: action.type,
            callback: action.callback
        };
    case AutoSaveActionTypes.AS_ACTION_UPDATE:
        return update(
            action,
            state
        );
    case AutoSaveActionTypes.AS_ACTION_DELETE:
        return {
            ...state,
            action: action.type,
            key: action.key,
            id: action.id,
            data: _.cloneDeep(action.data)
        };
    case AutoSaveActionTypes.AS_ACTION_CREATE:
        return {
            ...state,
            action: action.type,
            key: action.key,
            id: action.id,
            data: _.cloneDeep(action.data)
        };
    case AutoSaveActionTypes.AS_ACTION_IDLE:
        return idle(
            action,
            state
        );
    default:
        return state;
    }
};

export default reducer;
