import {Progress, Spinner} from "reactstrap";
import logo from "../../assets/protouree_logo.svg";
import React from "react";

/**
 *
 * Displays the Loading message
 *
 * @param {Object[]} props
 * @param {string} props[].showWidget
 * @param {number} props[].progressValue
 * @param {number} props[].progressMax
 * @param {string} props[].message
 * @param {Boolean} props[].useBackground
 *
 */
const Loading = (props) => {
    const showWidget = props.showWidget; // "progress" or "spinner"
    const {progressValue, progressMax, message} = props;
    const useBG = props.useBackground ? true : false;

    return (
        <div className={ useBG ? "pt-app-select-page" : null }>
            <div className="app-loading">
                <div className="app-loading-text text-center pt-login-dark">
                    <h1>
                        <img src={ logo } height="44" width="215" alt="protouree logo" />
                    </h1>
                    <h5 className="pb-4">Professional touring, entertainment, theatre and event management systems.</h5>
                    <span>{ message }</span>
                    { showWidget === "progress" ?
                        <Progress animated color="info" className="mt-5 progress-bg" value={ progressValue } max={ progressMax }>
                            {(progressValue)}%
                        </Progress>
                        :
                        <Spinner size="sm" color="primary" />
                    }
                </div>
            </div>
        </div>
    );
};

export default Loading;
