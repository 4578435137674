import * as globalActionTypes from "../actions/globalActionTypes";

const initialState = {
    liveProjects: [],
    allProjects: [],
    dayScheduleEventTypes: [],
    dayScheduleEventStatuses: [],
    dayScheduleBookingStatuses: [],
    incomeExpenditureTypes: [],
    bookingSources: [],
    commTypes: [],
    commsCategories: [],
    contactTypes: [],
    merchSoldBy: [],
    sessionTypes: [],
    websiteDisplay: [],
    websiteTicketAvailability: [],
    products: [],
    productTypes: [],
    compTypes: [],
    workshopReportDemographics: [],
    workshopReportAgeRanges: [],
    workshopReportVenueRatings: [],
    workshopReportStaffSupport: [],
    bookingContactTypes: [],
    bookingEventContactTypes: [],
    bookingEventReportingPartOfs: [],
    bookingEventStatuses: [],
    whereHeard: [],
    travelTypes: [],
    contactCategories: [],
    ACECategories: [],
    systemSettings: [],
    systemSettingGroups: [],
    equipmentTestTypes: [],
    dataLoaded: 0, // count to 2 before all loaded
    editorPaneHidden: false
};

const updateProject = (updatedProject, allProjects) => {
    let projects = allProjects.slice(0);
    const idx = projects.findIndex((p) => Number(p.project_id) === Number(updatedProject.project_id));

    projects[idx] = updatedProject;
    return projects;
};

const updateProjectPeople = (projectId, people, allProjects) => {
    let projects = allProjects.slice(0);
    const idx = projects.findIndex((p) => Number(p.project_id) === Number(projectId));

    projects[idx].people = people;
    return projects;
};

const updateProduct = (updatedProduct, allProducts) => {
    let products = allProducts.slice(0);
    const idx = products.findIndex((p) => Number(p.product_id) === Number(updatedProduct.product_id));

    products[idx] = updatedProduct;
    return products;
};

const updateCategory = (updatedCategory, contactCategories) => {
    let cats = contactCategories.slice(0);
    const idx = cats.findIndex((c) => Number(c.contact_category_id) === Number(updatedCategory.contact_category_id));

    cats[idx] = updatedCategory;
    return cats;
};

const sortProjects = (projs) => {
    const sorted = projs.sort((a, b) => a.project_name.toUpperCase() > b.project_name.toUpperCase());

    return sorted;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case globalActionTypes.GLOBAL_PROJECTS_LOADED:
        return {
            ...state,
            allProjects: sortProjects(action.allProjects),
            liveProjects: sortProjects(action.liveProjects),
            dataLoaded: state.dataLoaded + 1
        };
    case globalActionTypes.GLOBAL_PROJECT_UPDATED: {
        let projects = updateProject(
            action.project,
            state.allProjects
        );

        projects = sortProjects(projects);
        return {
            ...state,
            allProjects: projects,
            liveProjects: projects.filter((p) => Number(p.is_live) === 1)
        };
    }
    case globalActionTypes.GLOBAL_PROJECT_ADDED: {
        let projs = [...state.allProjects];

        projs.unshift(action.project);
        projs = sortProjects(projs);
        return {
            ...state,
            allProjects: projs
        };
    }
    case globalActionTypes.GLOBAL_PROJECT_PEOPLE_UPDATED: {
        const updatedProjects = updateProjectPeople(
            action.projectId,
            action.people,
            state.allProjects
        );

        return {
            ...state,
            allProjects: updatedProjects,
            liveProjects: updatedProjects.filter((p) => Number(p.is_live) === 1)
        };
    }
    case globalActionTypes.GLOBAL_PRODUCT_UPDATED:
        return {
            ...state,
            products: updateProduct(
                action.product,
                state.products
            )
        };
    case globalActionTypes.GLOBAL_PRODUCT_ADDED: {
        let prods = state.products.slice(0);

        prods.unshift(action.product);
        return {
            ...state,
            products: prods
        };
    }
    case globalActionTypes.GLOBAL_CATEGORY_UPDATED: {
        let parentCats = state.contactParentCategories;

        if (Number(action.category.contact_category_parent_id) === 0) {
            parentCats = updateCategory(
                action.category,
                parentCats
            );
            console.log(parentCats);
        }
        return {
            ...state,
            contactCategories: updateCategory(
                action.category,
                state.contactCategories
            ),
            contactParentCategories: parentCats
        };
    }
    case globalActionTypes.GLOBAL_CATEGORY_ADDED: {
        let cats = state.contactCategories.slice(0);
        let ACECategories = state.ACECategories.slice(0);
        let contactParentCategories = state.contactParentCategories.slice(0);

        if (Number(action.category.contact_category_parent_id) === 0) {
            contactParentCategories.unshift(action.category);
        } else if (Number(action.category.contact_category_parent_id) === globalActionTypes.ACE_PARENT_CATEGORY_ID) {
            ACECategories.unshift(action.category);
        }
        cats.unshift(action.category);

        return {
            ...state,
            contactCategories: cats,
            ACECategories: ACECategories,
            contactParentCategories: contactParentCategories
        };
    }
    case globalActionTypes.GLOBAL_SUPPORT_DATA_LOADED:
        return {
            ...state,
            // bookingEventTypes: action.bookingEventTypes,
            dayScheduleEventTypes: action.dayScheduleEventTypes,
            dayScheduleEventStatuses: action.dayScheduleEventStatuses,
            dayScheduleBookingStatuses: action.dayScheduleBookingStatuses,
            incomeExpenditureTypes: action.incomeExpenditureTypes,
            bookingSources: action.bookingSources,
            commTypes: action.commTypes,
            commsCategories: action.commsCategories,
            contactTypes: action.contactTypes,
            merchSoldBy: action.merchSoldBy,
            sessionTypes: action.sessionTypes,
            websiteDisplay: action.websiteDisplay,
            websiteTicketAvailability: action.websiteTicketAvailability,
            products: action.products,
            productTypes: action.productTypes,
            compTypes: action.compTypes,
            workshopReportDemographics: action.workshopReportDemographics,
            workshopReportAgeRanges: action.workshopReportAgeRanges,
            workshopReportVenueRatings: action.workshopReportVenueRatings,
            workshopReportStaffSupport: action.workshopReportStaffSupport,
            bookingContactTypes: action.bookingContactTypes,
            bookingEventContactTypes: action.bookingEventContactTypes,
            bookingEventReportingPartOfs: action.bookingEventReportingPartOfs,
            bookingEventStatuses: action.bookingEventStatuses,
            showReportCommissionFeeTypes: action.showReportCommissionFeeTypes,
            whereHeard: action.whereHeard,
            contactCategories: action.contactCategories,
            ACECategories: action.ACECategories,
            contactParentCategories: action.contactParentCategories,
            userGroups: action.userGroups,
            countries: action.countries,
            staff: action.staff,
            travelTypes: action.travelTypes,
            equipmentTestTypes: action.equipmentTestTypes,
            dataLoaded: state.dataLoaded + 1
        };
    case globalActionTypes.GLOBAL_SYSTEM_SETTINGS_LOADED:
        return {
            ...state,
            systemSettings: action.systemSettings,
            systemSettingGroups: action.systemSettingGroups,
            fullSystemSettings: action.fullSystemSettings,
            dataLoaded: state.dataLoaded + 1
        };
    case globalActionTypes.GLOBAL_CHANGE_PANE_VISIBILITY:
        return {
            ...state,
            editorPaneHidden: !state.editorPaneHidden
        };
    case globalActionTypes.GLOBAL_SHOW_PANE: {
        const hidden = state.editorPaneHidden;

        if (hidden) {
            return {
                ...state,
                editorPaneHidden: false
            };
        }
        return state;
    }
    default:
        return state;
    }
};

export default reducer;
